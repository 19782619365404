import { isXMLHttpRequest, parseError } from '@kontent-ai/errors';
import { captureException, captureMessage, logger as sentryLogger } from '@sentry/react';
import { trackExceptionToAppInsights } from './applicationInsights.ts';

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// When moving this file, make sure to update the grouping rules in Sentry.
// If out of sync, the functions below will appear as the source of an error.
// See https://docs.sentry.io/concepts/data-management/event-grouping/stack-trace-rules/
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

const logConsoleError: typeof console.error = (...params) => {
  // Do not spam console in production.
  if (self._clientConfig.logErrorsToConsole) {
    console.error(...params);
  }
};

function trackError(exception: unknown, error?: unknown): void {
  if (self._envConfig.appInsightsInstrumentationKey) {
    trackExceptionToAppInsights({ exception: parseError(exception, error) });
  }
}

/**
 * @param id A string uniquely distinguishing this error from others. MUST BE UNIQUE. Can be either
 * a unique error message, a filename, or – in case of multiple calls in the same file – a filename + number.
 * @param error
 */
export function logErrorToMonitoringTool<T>(id: string, error: Exclude<T, string>): void {
  logConsoleError(id, error);
  trackError(id, error);
  if (self._envConfig.isSentryEnabled) {
    if (isXMLHttpRequest(error)) {
      captureMessage(id, (scope) => {
        scope.setExtras({
          readyState: error.readyState,
          responseHeaders: error.getAllResponseHeaders(),
          responseType: error.responseType,
          responseTextSize: error.responseText.length,
          status: error.status,
        });
        scope.setLevel('error');
        return scope;
      });
    } else {
      captureException(error);
    }
  }
}

export function logErrorMessageToMonitoringTool(
  message: string,
  attributes?: Record<string, unknown>,
): void {
  logConsoleError(message, attributes);
  trackError(message);
  if (self._envConfig.isSentryEnabled) {
    sentryLogger.error(message, attributes);
  }
}
